import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import signatureStoreModule from '../signatureStoreModule';

export default function useSignatureModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'signature';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, signatureStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const signature = ref(null);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
    signature.value = null;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    const formData = new FormData();

    if (itemLocal.value.signatureFile) {
      formData.append('signature', itemLocal.value.signatureFile);
    }
    if (signature.value) {
      formData.append('signature', signature.value);
    }

    formData.append('name', itemLocal.value.name);

    isSubmitting.value = true;
    if (itemLocal.value.id && itemLocal.value.id > 0) {
      store
        .dispatch('signature/updateSignature', { data: formData, id: itemLocal.value.id })
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch('signature/createSignature', formData)
        .then(response => { emit('on-item-created', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const onSaveSignature = base64Image => {
    itemLocal.value.signatureFile = null;
    signature.value = base64Image;
  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    signature,
    resetItemLocal,
    resetModal,
    onSubmit,
    onSaveSignature,
  };
}
