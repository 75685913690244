import { ref, watch, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import signatureStoreModule from '../signatureStoreModule';

export default function useSignatureList() {
  const STORE_MODULE_NAME = 'signature';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, signatureStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    name: '',
    signature: '',
    signatureFile: null,
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Tên',
      field: 'name',
    },

    {
      label: 'Hình ảnh',
      field: 'signature',
    },
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
    },
  ];
  const rows = ref([]);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  //   API Call

  const fetchSignatures = () => {
    store
      .dispatch('signature/fetchSignatures', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const refetchSignatures = () => {
    fetchSignatures();
  };

  const deleteSignatures = signatures => {
    store
      .dispatch('signature/deleteSignatures', {
        ids: signatures.map(_obj => _obj.id),
      })
      .then(() => {
        fetchSignatures();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createSignature = () => {
    store
      .dispatch('signature/createSignature', {
        name: item.value.name,
      })
      .then(() => {
        fetchSignatures();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchSignatures();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });

  return {
    item,
    resetItem,

    columns,
    rows,
    selectedRows,
    searchTerm,
    isLoading,
    totalRecords,
    serverParams,

    refetchSignatures,
    fetchSignatures,
    deleteSignatures,
    createSignature,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
  };
}
